export default {
  data () {
    return {
      pallete: {
        $active_color: '#1ed6c2',
        $safe_color: '#1DC1AC',
        $dangerous_color: '#ff5252',
        $secondary_active_color: '#83f4ff',
        $primary_text_color: '#fff',
        $secondary_text_color: '#999',
        $secondary_bg_color: '#1b1820',
        $primary_bg_color: '#252127',
      }
    }
  },
  computed: {
    isMobile () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('android') ||
        userAgent.includes('mobile')
      )
    },
    isiPad () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('ipad') ||
        userAgent.indexOf('ipad') > -1 ||
        (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)
      )
    },
    isSafari () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (userAgent.indexOf('safari') > -1) && (userAgent.indexOf('chrome') === -1)
    }
  },
  methods: {
    sleep (msec) {
      return new Promise(resolve => setTimeout(resolve, msec))
    },
    random (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    getParams () {
      return EventBus.$emit('route-params')
    }
  }
}
