import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/sign-in'
  // },
  {
    path: '/',
    name: 'home',
    component: () =>
      import('@/views/PageHome')
  }
  // {
  //   path: '/tmp',
  //   name: 'tmp',
  //   props: true,
  //   component: () =>
  //     import('@/views/PageTmp')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
