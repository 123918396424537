import Vue from 'vue'
import Vuex from 'vuex'
import * as lang from './lang'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    lang: {
      namespaced: true,
      ...lang
    }
  }
})
